import { Component, OnInit, OnDestroy, HostListener, ChangeDetectorRef } from '@angular/core';
import { CurrentLineUserInfo } from './shared/models/current-line-user-info';
import { CurrentLiffInfo } from './shared/models/current-liff-info';
import { Subscription, Subject, Observable, from } from 'rxjs';
import { Store } from '@ngxs/store';
import { AppState, AppStateModel } from './core/store/app.state';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import {
  ShowGlobalSpinner,
  HideGlobalSpinner,
  HideMainNav,
  HideGlobalHeader,
  ToggleSideBar,
  UpdateLastActivityTimestamp
} from './core/store/app.action';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import jQuery from 'jquery';
import 'malihu-custom-scrollbar-plugin';
import { debounceTime, distinctUntilChanged, debounce } from 'rxjs/operators';
import moment from 'moment-timezone';
import { AuthService } from './core/services/auth.service';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { ExportChatOptionDialogComponent } from './shared/components/export-chat-option-dialog/export-chat-option-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HcpService } from './core/services/hcp.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'carrot-hcp-liff';
  public currentLiffInfo: CurrentLiffInfo;
  public currentLiffInfo$: Subscription;
  public currentLineUserInfo: any;
  public currentLineUserInfo$: Subscription;
  public currentLineAccessToken: string;
  public currentLineAccessToken$: Subscription;
  public currentLineIdToken: string;
  public currentLineIdToken$: Subscription;
  public shouldShowSpinner$: Subscription;
  public routeChanged$: Subscription;
  public shouldShowMainNav$: Subscription;
  public shouldShowMainNav = false;
  public shouldShowGlobalHeader$: Subscription;
  public shouldShowGlobalHeader = false;
  public shouldShowSideBar$: Subscription;
  public shouldShowSideBar = false;
  public globalSpinnerMessage$: Subscription;
  public globalSpinnerMessage = 'Loading...';
  public appName = environment.defaultPageTitle;
  private userActivity: Subject<any>;
  private userActivity$: Subscription;
  private activityTimer: any;
  private currentAdminUser$: Subscription;

  constructor(
    private analytics: Angulartics2GoogleGlobalSiteTag,
    private store: Store,
    private spinner: NgxSpinnerService,
    private router: Router,
    private titleService: Title,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private hcpService: HcpService,
  ) {
    this.analytics.startTracking();
    this.titleService.setTitle(environment.defaultPageTitle);
    this.userActivity = new Subject();
    this.userActivity$ = this.userActivity
      .pipe(
        distinctUntilChanged(),
        debounceTime(300),
      )
      .subscribe((e) => {
        if (this.authService.hasIdentity()) {
          const now = Number(moment().tz(environment.TIMEZONE).format('X'));
          this.store.dispatch(new UpdateLastActivityTimestamp(now));
        }
      });
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    this.userActivity.next(e);
  }
  @HostListener('document:keyup', ['$event'])
  onKeyUp(e) {
    this.userActivity.next(e);
  }

  ngOnInit() {
    jQuery('document').ready(() => {
      /* jQuery('#sidebar').mCustomScrollbar({
        theme: 'minimal',
      }); */
      jQuery('#global-temp-loading').hide();
    });
    this.currentAdminUser$ = this.store.select(AppState.currentUser).subscribe((user) => {
      if (user) {
        this.startActivityTimer();
      } else {
        this.stopActivityTimer();
      }
    });
    this.routeChanged$ = this.router.events.subscribe((evt) => {
      // console.log('routeChanged', val);
      if (evt instanceof NavigationStart) {
        // console.log('show global spinner');
        this.store.dispatch(new ShowGlobalSpinner());
      } else if (evt instanceof NavigationEnd) {
        // console.log('hide global spinner');
        this.store.dispatch(new HideGlobalSpinner());
        window.scrollTo(0, 0);
      }
    });
    this.currentLiffInfo$ = this.store.select(AppState.currentLiffInfo)
      .subscribe((liffInfo) => this.currentLiffInfo = liffInfo);
    this.currentLineUserInfo$ = this.store.select(AppState.currentLineUserInfo)
      .subscribe((userInfo) => {
        this.currentLineUserInfo = userInfo;
        let userId = null;
        if (this.currentLineUserInfo) {
          userId = this.currentLineUserInfo.userId;
          this.store.dispatch(new HideMainNav());
          this.store.dispatch(new HideGlobalHeader());
        }
      });
    this.currentLineAccessToken$ = this.store.select(AppState.currentLineAccessToken)
      .subscribe((token) => {
        this.currentLineAccessToken = token;
      });
    this.currentLineIdToken$ = this.store.select(AppState.currentLineIdToken)
      .subscribe(async (token) => {
        this.currentLineIdToken = token;
        if (this.currentLineIdToken) {
          if (!this.hcpService.hasIdentity()) {
            await this.hcpService.loadIdentity(token).toPromise();
          }
          const identity = this.hcpService.getIdentity();
          if (identity) {
            const trackUserId = identity.email || identity.line_user_id;
            this.analytics.setUsername(trackUserId);
            window.gtag('set', 'user_properties', {
              dataSource: 'liffapp',
              campaignName: 'rtlloa',
              campaignSource: 'liffapp',
              campaignMedium: 'line',
              dimension1: trackUserId,
              dimension2: moment().tz(environment.TIMEZONE).format('YYYY-MM-DD HH:mm'),
              dimension3: identity.specialty, // specialty
              dimension4: identity.profession, // profession
              dimension5: identity.status, // status
              dimension6: identity.default_richmenu, // default richmenu
              dimension7: identity.line_user_id, // LINE UID
            });
          }
          this.store.dispatch(new HideMainNav());
          this.store.dispatch(new HideGlobalHeader());
        }
      });
    this.shouldShowSpinner$ = this.store.select(AppState.shouldShowSpinner)
      .subscribe((shouldShowSpinner) => {
        let shouldIgnore = false;
        const liffMode = this.store.selectSnapshot(AppState.currentLiffMode);
        if ([
          'RICH_MENU',
        ].indexOf(liffMode) !== -1) {
          shouldIgnore = true;
        }
        if (!shouldIgnore) {
          if (shouldShowSpinner) {
            this.spinner.show();
          } else {
            setTimeout(() => {
              this.spinner.hide();
            }, 500);
          }
        }
      });
    this.globalSpinnerMessage$ = this.store.select(AppState.globalSpinnerMessage)
      .subscribe((message) => this.globalSpinnerMessage = message);
    this.shouldShowMainNav$ = this.store.select(AppState.shouldShowMainNav)
      .subscribe((value) => {
        this.shouldShowMainNav = value || false;
      });
    this.shouldShowGlobalHeader$ = this.store.select(AppState.shouldShowGlobalHeader)
      .subscribe((value) => {
        this.shouldShowGlobalHeader = value || false;
        this.cdr.detectChanges();
      });
    this.shouldShowSideBar$ = this.store.select(AppState.shouldShowSideBar)
      .subscribe((visible) => {
        this.shouldShowSideBar = visible;
      });
    this.store.dispatch(new HideMainNav());
  }

  toggerSideBar() {
    this.store.dispatch(new ToggleSideBar());
  }

  get userName() {
    if (this.currentLineUserInfo) {
      return this.currentLineUserInfo.userId;
    }
  }

  get isLiffApp() {
    if (this.currentLineUserInfo) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    if (this.currentLiffInfo$) {
      this.currentLiffInfo$.unsubscribe();
      this.currentLiffInfo$ = null;
    }
    if (this.currentLineUserInfo$) {
      this.currentLineUserInfo$.unsubscribe();
      this.currentLineUserInfo$ = null;
    }
  }

  get currentUser() {
    return this.store.selectSnapshot(AppState.currentUser);
  }

  get isAdmin() {
    if (this.currentUser && this.currentUser.is_admin) {
      return true;
    }
    return false;
  }

  get isExpert() {
    if (this.currentUser && this.currentUser.is_expert) {
      return true;
    }
    return false;
  }

  startActivityTimer() {
    this.activityTimer = setTimeout(async () => {
      // console.log('check activity timer');
      const lastActivityTimestampText = Number(this.store.selectSnapshot(AppState.lastActivityTimestamp)) || -1;
      const lastActivityTimestamp = moment(lastActivityTimestampText, 'X').tz(environment.TIMEZONE);
      const now = moment().tz(environment.TIMEZONE);
      if (lastActivityTimestamp.isValid() && lastActivityTimestampText !== -1) {
        const timeDiff = now.diff(lastActivityTimestamp, 'seconds');
        console.log(' ==>', timeDiff);
        if (timeDiff > environment.ACTIVITY_IDEL_TIMEOUT) {
          if (this.authService.hasIdentity()) {
            // clear timer
            this.stopActivityTimer();
            await this.store.dispatch(new UpdateLastActivityTimestamp(-1));
            console.log('idel timeout -> logging out...');
            this.router.navigate(['/auth/logout']);
          }
        }
      }
      if (lastActivityTimestampText !== -1) {
        this.startActivityTimer();
      }
    }, environment.ACTIVITY_TIMER_INTERVAL);
  }
  stopActivityTimer() {
    clearTimeout(this.activityTimer);
    this.store.dispatch(new UpdateLastActivityTimestamp(-1));
  }

  async exportChats() {
    const exportChatOptionDialogRef = this.dialog.open(ExportChatOptionDialogComponent, {
      width: '600px',
    });
    const options = await exportChatOptionDialogRef.afterClosed().toPromise();
    console.log('exportChats', options);
  }
}
